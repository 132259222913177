import React, {Component} from 'react'
import './FMembers.css'
import {Container, Card, Button, ListGroup} from "react-bootstrap";
import * as Profiles from '../Pages/Images/Profiles/ProfilesLoader';

export default class Members extends Component {
    render() {
        return ( 
        <> 
        <div className="about-layout d-md-flex flex-md-column align-items-center">
            <Container className="container-about d-md-flex flex-md-column mr-auto">
                <h2>Наши специалисты</h2>
            </Container>

            <Container className='container-members-cards gap-3 mt-5 d-flex justify-content-center flex-wrap'>
                        <Card style={{ width: '19rem'}}>
                            <Card.Img variant="top" src={Profiles.profile0} objectFit="cover"/>
                            <Card.Body>
                                <Card.Title>Сабадаш Андрей Владимирович</Card.Title>
                                <Card.Text>
                                Главный врач клиники «Хирург и Я»
                                </Card.Text>
                            </Card.Body>
                            <ListGroup className="list-group-flush">
                                <ListGroup.Item>Врач-хирург 1 категории</ListGroup.Item>
                                <ListGroup.Item>Врач УЗИ</ListGroup.Item>
                                <ListGroup.Item>Трансфузиолог</ListGroup.Item>
                            </ListGroup>
                            <Card.Body className='flex-grow-0'>
                            <Container className='container-members-buttons d-md-flex flex-md-column mr-auto gap-3 mt-auto py-3'>
                                <Button variant="primary" href='https://prodoctorov.ru/ekaterinburg/vrach/495857-sabadash/'>Отзывы</Button>
                                <Button variant="primary" href='https://prodoctorov.ru/ekaterinburg/vrach/495857-sabadash/#educations'>Образование</Button>
                            </Container>
                            </Card.Body>
                        </Card>

                        <Card style={{ width: '19rem' }}>
                            <Card.Img variant="top" src={Profiles.profile1}/>
                            <Card.Body>
                                <Card.Title>Шушанов Антон Павлович</Card.Title>
                                <Card.Text>
                                Заведующий хирургическим отделением
                                </Card.Text>
                            </Card.Body>
                            <ListGroup className="list-group-flush">
                                <ListGroup.Item>Врач-хирург Высшей категории</ListGroup.Item>
                                <ListGroup.Item>Врач ультразвуковой диагностики</ListGroup.Item>
                                <ListGroup.Item>Онколог</ListGroup.Item>
                            </ListGroup>
                            <Card.Body className='flex-grow-0'>
                            <Container className='container-members-buttons d-md-flex flex-md-column mr-auto gap-3 mt-auto py-3'>
                                <Button variant="primary" href='https://prodoctorov.ru/ekaterinburg/vrach/229097-shushanov/'>Отзывы</Button>
                                <Button variant="primary" href='https://prodoctorov.ru/ekaterinburg/vrach/229097-shushanov/#educations'>Образование</Button>
                            </Container>
                            </Card.Body>
                        </Card>

                        <Card style={{ width: '19rem' }}>
                            <Card.Img variant="top" src={Profiles.profile2}/>
                            <Card.Body>
                                <Card.Title>Камилов Магомед Юсупович</Card.Title>
                                <Card.Text>
                                </Card.Text>
                            </Card.Body>
                            <ListGroup className="list-group-flush">
                                <ListGroup.Item>Врач-хирург</ListGroup.Item>
                                <ListGroup.Item>Колопроктолог</ListGroup.Item>
                            </ListGroup>
                            <Card.Body className='flex-grow-0'>
                            <Container className='container-members-buttons d-md-flex flex-md-column mr-auto gap-3 mt-auto py-3'>
                                <Button variant="primary" href='https://prodoctorov.ru/ekaterinburg/vrach/735784-kamilov/'>Отзывы</Button>
                                <Button variant="primary" href='https://prodoctorov.ru/ekaterinburg/vrach/735784-kamilov/#educations'>Образование</Button>
                            </Container>
                            </Card.Body>
                        </Card>

                        <Card style={{ width: '19rem' }}>
                            <Card.Img variant="top" src={Profiles.profile3}/>
                            <Card.Body>
                                <Card.Title>Липин Глеб Игоревич</Card.Title>
                                <Card.Text>
                                </Card.Text>
                            </Card.Body>
                            <ListGroup className="list-group-flush">
                                <ListGroup.Item>Врач-хирург</ListGroup.Item>
                                <ListGroup.Item>Травматолог-ортопед</ListGroup.Item>
                            </ListGroup>
                            <Card.Body className='flex-grow-0'>
                            <Container className='container-members-buttons d-md-flex flex-md-column mr-auto gap-3 mt-auto py-3'>
                                <Button variant="primary" href='https://prodoctorov.ru/ekaterinburg/vrach/989884-lipin/'>Отзывы</Button>
                                <Button variant="primary" href='https://prodoctorov.ru/ekaterinburg/vrach/989884-lipin/#educations'>Образование</Button>
                            </Container>
                            </Card.Body>
                        </Card>

                        <Card style={{ width: '19rem' }}>
                            <Card.Img variant="top" src={Profiles.profile6}/>
                            <Card.Body>
                                <Card.Title>Дадашев Малик Рафикович</Card.Title>
                                <Card.Text>
                                </Card.Text>
                            </Card.Body>
                            <ListGroup className="list-group-flush">
                                <ListGroup.Item>Врач-хирург</ListGroup.Item>
                            </ListGroup>
                            <Card.Body className='flex-grow-0'>
                            <Container className='container-members-buttons d-md-flex flex-md-column mr-auto gap-3 mt-auto py-3'>
                                <Button variant="primary" href='https://prodoctorov.ru/ekaterinburg/vrach/971760-dadashev/'>Отзывы</Button>
                                <Button variant="primary" href='https://prodoctorov.ru/ekaterinburg/vrach/971760-dadashev/#educations'>Образование</Button>
                            </Container>
                            </Card.Body>
                        </Card>

                        <Card style={{ width: '19rem' }}>
                            <Card.Img variant="top" src={Profiles.profile4}/>
                            <Card.Body>
                                <Card.Title>Гилева Елена Александровна</Card.Title>
                                <Card.Text>
                                Медицинская сестра
                                </Card.Text>
                            </Card.Body>
                            <ListGroup className="list-group-flush">
                                <ListGroup.Item>Старшая Медицинская сестра</ListGroup.Item>
                            </ListGroup>
                            <Card.Body className='flex-grow-0'>
                                <Container className='container-members-buttons d-md-flex flex-md-column mr-auto gap-3 mt-auto py-3'>
                                {/* <Button variant="primary">Отзывы</Button>
                                <Button variant="primary">Образование</Button> */}
                                </Container>
                            </Card.Body>
                        </Card>

                        <Card style={{ width: '19rem' }}>
                            <Card.Img variant="top" src={Profiles.profile5}/>
                            <Card.Body>
                                <Card.Title>Набор сотрудников</Card.Title>
                                <Card.Text>
                                Ведется набор сотрудников в нашу клинику!
                                </Card.Text>
                            </Card.Body>
                        </Card>
            </Container>
        </div> 
        </>
    )
  }
}
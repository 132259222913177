import React, { Component } from 'react'
import FContacts from '../Compoments/FContacts'

export default class Contacts extends Component {
  render() {
    return (
      <FContacts />
    )
  }
}

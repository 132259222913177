import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from "react-scroll-to-top";
import Header from './Compoments/Header';
import Footer from './Compoments/Footer'

function App() {
  return (
    <div>
      <Header />
      <Footer />
      <ScrollToTop className='scroll-to-top' smooth />
    </div>
  );
}

export default App;

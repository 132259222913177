import React, { Component } from 'react';
import './YandexMapLoader.css';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

export default class YandexMapLoader extends Component {
  render() {
    return (
      <YMaps apiKey="55aa6134-4830-4b1a-a807-0e8f61210f29">
        <div className='test'>
        <Map
        type="yandex"
          defaultState={{
            center: [56.784684, 60.515468],
            zoom: 18,
          }}
          controls={['zoomControl', 'searchControl', 'typeSelector']}
          width={300}
          height={300}
          maxwidth={300}
          maxheight={300}
          minzoom={10}
          maxzoom={20}
        >
          <Placemark
            geometry={{ type: 'Point', coordinates: [56.784684, 60.515468] }}
            properties={{
              balloonContent: (
                <div>
                  <h1>Хирург и Я</h1>
                  <p>Медцентр, клиника, амбулатория, здравпункт, медпункт</p>
                  <p>Адрес: Свердловская область, Екатеринбург, проспект Академика Сахарова, 51</p>
                  <p>Телефон: +7 (922) 101-91-12</p>
                  <a href="klinika-khirurgiya.ru">Сайт организации</a>
                </div>
              ),
            }}
          />
        </Map>
        </div>
      </YMaps>
    );
  }
}
import React, { useState, useEffect } from "react";
import "./FFeatures.css";
import { Container, Row, Col } from "react-bootstrap";
import icon from "./icon.svg";

export default function FFeatures() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isRowEnabled = windowWidth <= 425;

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
 
 return (
   <>
     <Row>
       <Container className="container container-features-layout d-md-flex align-self-stretch">
         <div class="container">
           <div class="row g-4">
           <div class="col-md-6 d-flex align-items-stretch">
               <Col className={`feature-card d-md-flex align-items-baseline ${isRowEnabled ? "row" : ""}`}>
                 <img
                   src={icon}
                   height="30"
                   width="30"
                   className="feature-icon d-inline-block align-top"
                   alt="FeaturesIcon"
                 /><Container className="container-features-text">
                   <h2>Профессионализм</h2>
                   <p>Мы подобрали настоящую команду профессионалов - главным преимуществом Клиники "Хирург и Я" является персонал!</p>
                 </Container>
               </Col>
             </div>

             <div class="col-md-6 d-flex align-items-stretch">
             <Col className={`feature-card d-md-flex align-items-baseline ${isRowEnabled ? "row" : ""}`}>
                 <img
                   src={icon}
                   height="30"
                   width="30"
                   className="feature-icon d-inline-block align-top"
                   alt="FeaturesIcon"
                 /><Container className="container-features-text">
                   <h2>Доступные цены</h2>
                   <p>Мы предлагаем высокое качество лечения по разумной и доступной цене.</p>
                 </Container>
               </Col>
             </div>

             <div class="col-md-6 d-flex align-items-stretch">
               <Col className={`feature-card d-md-flex align-items-baseline ${isRowEnabled ? "row" : ""}`}>
                 <img
                   src={icon}
                   height="30"
                   width="30"
                   className="feature-icon d-inline-block align-top"
                   alt="FeaturesIcon"
                 /><Container className="container-features-text">
                   <h2>Качественный сервис</h2>
                   <p>С уважением к пациенту, с любовью к хирургии - это наш девиз! Уважительное и доброжелательное отношение к каждому пациенту, залог нашего успеха.</p>
                 </Container>
               </Col>
             </div>

             <div class="col-md-6 d-flex align-items-stretch">
               <Col className={`feature-card d-md-flex align-items-baseline ${isRowEnabled ? "row" : ""}`}>
                 <img
                   src={icon}
                   height="30"
                   width="30"
                   className="feature-icon d-inline-block align-top"
                   alt="FeaturesIcon"
                 /><Container className="container-features-text">
                   <h2>Надежность</h2>
                   <p>Мы создали все условия для вашего скорейшего выздоровления.</p>
                 </Container>
               </Col>
             </div>
           </div>
         </div>
       </Container>
     </Row>
   </>
 );
}
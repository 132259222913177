import React, { Component } from 'react'
import "./Footer.css";
import { Nav, Navbar, Container, Accordion, Row, ListGroup } from "react-bootstrap";

import Reviews from "./FooterReviews";
export default class Footer extends Component {
  render() {
    return (
      <>
      <div className="footer mt-auto py-3">
          <Container className="container-features container-footer d-md-flex flex-md-column mr-auto align-items-center mb-5 mt-5">
              <h2>Хирург и Я</h2>
            <Navbar  collapseOnSelect expand="md">
              <Container className='container-footer-layout justify-content-center'>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse
                    className="mr-flex justify-content-center"
                    id="responsive-navbar-nav"
                  >
                  <Nav className="nav-list mr-auto">
                    <Nav.Link className='nav-item text-white' href="/">Новости</Nav.Link>
                    <Nav.Link className='nav-item text-white' href="/about">О Нас</Nav.Link>
                    <Nav.Link className='nav-item text-white' href="/prices">Услуги</Nav.Link>
                    <Nav.Link className='nav-item text-white' href="/members">Врачи</Nav.Link>
                    <Nav.Link className='nav-item text-white' href="/documents">Документация</Nav.Link>
                    <Nav.Link className='nav-item text-white' href="/contacts">Контакты</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </Container>
          <Container>
          <Row>
        <Container className='footer-buttons d-flex flex-column gap-3'>
          <Accordion className='footer-accordion' defaultActiveKey="1">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Дополнительная информация</Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  <ListGroup.Item>Контактный телефон: +7 (922) 101-91-12</ListGroup.Item>
                  <ListGroup.Item>Электронная почта: klinika.khirurgiya@bk.ru</ListGroup.Item>
                  <ListGroup.Item>Лицензия № Л041-01021-66/00587070 от 25.07.2022 г.</ListGroup.Item>
                  <ListGroup.Item>Copyright © 2024 Хирург и Я. Все права защищены.</ListGroup.Item>
                  <ListGroup.Item>Сделано Group FAKEDOWNBOYS.</ListGroup.Item>
                  <ListGroup.Item>Материалы, размещенные на данной странице, носят информационный характер и предназначены для образовательных целей. ООО «КПП Хирург и Я» не несёт ответственности за возможные негативные последствия, возникшие в результате использования информации, размещенной на сайте klinika-khirurgiya.ru ООО «Клиника послеоперационного периода Хирург и Я » Лицензия № Л041-01021-66/00587070 от 25.07. 2022 г. ИМЕЮТСЯ ПРОТИВОПОКАЗАНИЯ. НЕОБХОДИМО ПРОКОНСУЛЬТИРОВАТЬСЯ СО СПЕЦИАЛИСТОМ</ListGroup.Item>
                  <ListGroup.Item className='fw-medium' variant='success' onClick={() => window.open('https://yandex.ru/maps/org/khirurg_i_ya/54144790768/reviews/?from=mapframe&ll=60.516285%2C56.784487&z=17')}>Отзывы Яндекс</ListGroup.Item>
                  <ListGroup.Item className='fw-medium' variant='success' onClick={() => window.open('https://2gis.ru/ekaterinburg/firm/70000001063542472')}>2ГИС</ListGroup.Item>
                  <ListGroup.Item className='fw-medium' variant='success' onClick={() => window.open('https://prodoctorov.ru/ekaterinburg/lpu/86448-hirurg-i-ya/')}>Продокторов</ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className='footer-accordion' defaultActiveKey="1">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Время работы клиники</Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  <ListGroup.Item>Вторник - пятница с 14:00 до 21:00</ListGroup.Item>
                  <ListGroup.Item>Суббота - воскресенье с 14:00 до 20:00</ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className='footer-accordion' defaultActiveKey="1">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Отзывы о клинике «Хирург и Я»</Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  <ListGroup.Item><Reviews /></ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </Row>
          </Container>
        </div>
        
      </>
    )
  }
}

<script defer src="https://prodoctorov.ru/static/js/widget_big.js?v7"></script>
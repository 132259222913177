import React, { Component } from "react";
import { Container, ListGroup, Button } from "react-bootstrap";
import "./FAbout.css";
import logo from "../Pages/Images/about.jpg";
import FFeatures from "../Compoments/FFeatures";
import FGallery from "../Compoments/FGallery";
import FFaq from "../Compoments/FFaq";

export default class About extends Component {
  render() {
    return (
      <>
        <div className="about-layout d-md-flex flex-md-row align-items-center">
          <Container className="container-about d-md-flex flex-md-column mr-auto">
            <h2>О Нас</h2>
            <h3>Хирургическая клиника Екатеринбурга</h3>
            <p>С уважением к пациенту, с любовью к хирургии</p>
          </Container>
          <Container className="container-about-image">
            <img src={logo} alt="aboutlogo" className="aboutlogo-image" />
          </Container>
        </div>
        
        <div className="intro">
          <Container className="container-intro d-md-flex flex-md-column mr-auto mb-5">
              <ListGroup className="intro-layout me-auto">
                  <ListGroup.Item><h4>Клиника "Хирург и Я" - уникальный медицинский центр, где оказывается качественная амбулаторная хирургическая помощь.</h4></ListGroup.Item>
                  <ListGroup.Item><span className="span-bold">Наша основная задача - помощь пациентам в послеоперационный период:</span></ListGroup.Item>
                  <ListGroup.Item><span className="span-bold">Перевязка раны</span> проводится опытным врачом-хирургом, что исключает развитие осложнений.</ListGroup.Item>
                  <ListGroup.Item><span className="span-bold">Пункции под УЗИ контролем</span> - при скоплении жидкости в области послеоперационной раны, врач безболезненно откачает её.</ListGroup.Item>
                  <ListGroup.Item><span className="span-bold">Снятие швов</span> - врач оценит состояние раны и безболезненно уберёт швы.</ListGroup.Item>
                  <ListGroup.Item><span className="span-bold">Промывка, подшивание, удаление дренажа и др.</span></ListGroup.Item>
                  <ListGroup.Item>Также мы оказываем спектр услуг, направленных на <span className="span-bold">КОНСУЛЬТАТИВНУЮ ПОМОЩЬ</span> в области <span className="span-bold">хирургии, колопроктологии и травматологии.</span> Проводим амбулаторные хирургические вмешательства: удаление любых новообразований (жировик, липома, атерома, гигромы), иссечение вросшего ногтя, вскрытие абсцессов (воспаленная атерома, панариций, паронихий, подкожный парапроктит), лимфорея.</ListGroup.Item>
              </ListGroup>
          </Container>
        </div>

        <div className="features">
          <Container className="container-features d-md-flex flex-md-column mr-auto align-items-center mb-5">
            <h2>Преимущества</h2>
            <p>Почему стоит выбрать именно нас?</p>
          </Container>
          <Container className="container-features-block">
            <FFeatures />
          </Container>
        </div>

        <div className="gallery">
        <Container className="container-features d-md-flex flex-md-column mr-auto align-items-center mb-5 mt-5">
            <h2>Галерея</h2>
          </Container>
          <Container className="container-gallery-block">
            <FGallery />
          </Container>
        </div>

        <div className="prices">
        <Container className="container-features d-md-flex flex-md-column mr-auto align-items-center mb-5 mt-5">
            <h2>Услуги</h2>
            <h4>Услуги и их стоимость</h4>
            <p>Предлагаем Вам ознакомиться с нашим списком услуг и стоимостью каждой из них.</p>
            <Button variant="warning" type="submit" onClick={() => { window.location.href = "/prices";}}>Услуги</Button>
          </Container>
        </div>

        <div className="faq d-md-block flex-md-row mr-auto align-items-center mb-5 mt-5">
          <Container className="container-features d-md-flex flex-md-column mr-auto align-items-center mb-5">
            <h2>FAQ</h2>
            <h4>Популярные вопросы</h4>
          </Container>
          <Container className="container-faq-block">
            <FFaq />
          </Container>
        </div>
      </>
    );
  }
}

import { useEffect } from 'react';

function FooterReviews() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://prodoctorov.ru/static/js/widget_big.js?v7";
        script.defer = true;
        script.async = true;
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        }
    
      })
  return (
    <>
    <div id="pd_widget_big"  data-lpu="86448" className='m-auto'>
        <div class="pd_rate_header">Отзывы о клинике «Хирург и Я»
        <a target="#" rel="noopener" class="pd_rate_new" href="https://prodoctorov.ru/new/rate/lpu/86448/"
        >Оставить отзыв</a></div>

        <div id="pd_widget_big_content"></div>
        <a target="#" rel="noopener" href="https://prodoctorov.ru/ekaterinburg/lpu/86448-hirurg-i-ya/#otzivi" class="pd_read_all">Читать все отзывы</a>

        <span id="pd_powered_by"><a target="#" rel="noopener" href="https://prodoctorov.ru"><img alt='Powered by Prodoctorov' class='pd_logo' src="https://prodoctorov.ru/static/_v1/pd/logos/logo-pd-widget.png"/></a></span>
    </div>
    </>
  )
}

export default FooterReviews
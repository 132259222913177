import { useEffect } from 'react';

function MedButton() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://booking.medflex.ru/components/round/round_widget_button.js";
        script.charset = "utf-8";
        script.defer = true;
        script.async = true;
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        }
    
      })
  return (
    <>
    <div id="medflexRoundWidgetData" data-src="https://booking.medflex.ru/?user=ca8a44eeef5366406b82a6a95db31e80&isRoundWidget=true"></div>
    </>
  )
}

export default MedButton
import React, { Component } from 'react'
import FMembers from '../Compoments/FMembers';

export default class Members extends Component {
  render() {
    return (
      <FMembers />
    )
  }
}

import React, {Component} from 'react'
import {Container, Row, Accordion, Figure, Image} from "react-bootstrap";

export default class FDocuments extends Component {
    render() {
        const { title, text, link, image, image2} = this.props;

        return ( 
        <> 
        <Container className='mt-3 mb-3'>
        <Row>
            <Container>
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{title}</Accordion.Header>
                        <Accordion.Body className='d-md-flex flex-md-column'>
                            {text}
                             {link && <a href={link}>{link}</a>}
                             <Figure>
                                <Image src={image}/>
                                <Image src={image2}/>
                            </Figure>
                            </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Container>
        </Row> 
        </Container>
        </>
    )
  }
}
import React, { Component } from "react";
import { Nav, Navbar, Container, Button, ListGroup, ListGroupItem } from "react-bootstrap";
import './Header.css';
import logo from "./logo.png";
import VKIcon from "../Pages/Images/Other/vk.svg";
import PDIcon from "../Pages/Images/Other/pd.png";
import WAIcon from "../Pages/Images/Other/whatsapp.svg";
import TGIcon from "../Pages/Images/Other/tg.svg";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import News from "../Pages/News";
import About from "../Pages/About";
import Prices from "../Pages/Prices";
import Members from "../Pages/Members";
import Contacts from "../Pages/Contacts";
import Documents from "../Pages/Documents";
import MedButton from "./MedButton";

export default class Header extends Component {
  render() {

    const handleGalleryClick = (e) => {
      e.preventDefault();
      
      const galleryElement = document.querySelector('.gallery');
      
      if (galleryElement) {
        galleryElement.scrollIntoView({ behavior: 'smooth' });
      } else {
        window.location.href = "/about";
      }
    };
    return (
      <>
      <div className='header-layout'>
        <Navbar  collapseOnSelect expand="lg">
          <Container className='container-header-layout'>
            <Container className="d-flex align-items-center flex-wrap gap-2">
              <Navbar.Brand href="/" className="d-flex align-items-center gap-2 me-auto">
                <img
                  src={logo}
                  height="30"
                  width="30"
                  className="d-inline-block align-top"
                  alt="Logo"
                />
              </Navbar.Brand>
              <a className="header-tel me-auto" href="tel:+79221019112">+7 (922)101-911-2</a>
              
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse
                className="mr-flex justify-content-center"
                id="responsive-navbar-nav"
              >
                <Nav className="header-navbar mr-auto align-items-center">
                  <Nav.Link className='nav-item' href="/">Новости</Nav.Link>
                  <Nav.Link className='nav-item' href="/about">О Нас</Nav.Link>
                  <Nav.Link className='nav-item' href="/prices">Услуги</Nav.Link>
                  <Nav.Link className='nav-item' href="/members">Врачи</Nav.Link>
                  <Nav.Link className='nav-item' href="/documents">Документация</Nav.Link>
                  <Nav.Link className='nav-item' href="/contacts">Контакты</Nav.Link>
                  <Nav.Link className='nav-item' href="/about" onClick={handleGalleryClick}>Галерея</Nav.Link>
                </Nav>
                
              </Navbar.Collapse>
              <ListGroup className="list-group-flush">
                <ListGroupItem className='nav-item-address'>г.Екатеринбург,</ListGroupItem>
                <ListGroupItem className='nav-item-address'>пр. Академика Сахарова, д.51</ListGroupItem>
              </ListGroup>
            </Container>
          </Container>
        </Navbar>
        <Container className='container-header__text d-flex flex-column justify-content-center align-items-center mr-auto'>
        <h1 className="font-large text-sm">Клиника послеоперационного периода "Хирург и Я"</h1>
          <Button href="tel:+79221019112" variant="warning">Позвоните нам!</Button>
          <Container className="header-icons d-flex flex-row justify-content-center align-items-center mr-auto gap-3">
          <a href="https://vk.com/klinika.khirurgiya"><img className="header-social" src={VKIcon} alt="Вконтакте" /></a>
          <a href="https://t.me/klinika_khirurgiya"><img src={TGIcon} alt="Телеграм" /></a>
          <a href="https://wa.me/79221019112"><img src={WAIcon} alt="WhatsApp" /></a>
          <a href="https://prodoctorov.ru/ekaterinburg/lpu/86448-hirurg-i-ya/"><img src={PDIcon} alt="Продокторов" /></a>
          </Container>
        </Container>
        
        <MedButton />
        </div>
        <Router>
          <Routes>
            <Route exact path="/" element={<News/>} />
            <Route exact path="/about" index element={<About/>} />
            <Route exact path="/prices" element={<Prices/>} />
            <Route exact path="/members" element={<Members/>} />
            <Route exact path="/contacts" element={<Contacts/>} />
            <Route exact path="/documents" element={<Documents/>} />
          </Routes>
        </Router>
      </>
    );
  }
}

<script defer src="https://booking.medflex.ru/components/round/round_widget_button.js" charset="utf-8"></script>
import React, { Component } from 'react';
import FPricing from '../Compoments/FPricing';

export default class Prices extends Component {
  render() {
    return (
      <FPricing />
    )
  }
}

import React from "react";
import { Container, Row, Accordion, ListGroup } from "react-bootstrap";

export default function FFaq() {
  return (
    <>
      <Row>
        <Container>
          <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Кто работает в клинике "Хирург и Я"?</Accordion.Header>
          <Accordion.Body>
          В клинике "Хирург и Я" работают высококвалифицированные хирурги с многолетним опытом работы. Все врачи имеют сертификаты и дипломы, подтверждающие их квалификацию.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Какие гарантии дает клиника "Хирург и Я"?</Accordion.Header>
          <Accordion.Body>
            <ListGroup>
                <ListGroup.Item variant="success">Высокое качество медицинских услуг.</ListGroup.Item>
                <ListGroup.Item variant="success">Безопасность и комфорт лечения.</ListGroup.Item>
                <ListGroup.Item variant="success">Индивидуальный подход к каждому пациенту.</ListGroup.Item>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Сколько стоит лечение в клинике "Хирург и Я"?</Accordion.Header>
          <Accordion.Body>
          Стоимость лечения в клинике "Хирург и Я" зависит от вида операции, сложности случая и других факторов. Точную цену можно узнать после консультации с врачом.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Как записаться на консультацию в клинику "Хирург и Я"?</Accordion.Header>
          <Accordion.Body>
          Записаться на консультацию в клинику "Хирург и Я" можно по:
            <ListGroup>
              <ListGroup.Item variant="success">Телефону +79221019112</ListGroup.Item>
              <ListGroup.Item variant="success" href="https://prodoctorov.ru/ekaterinburg/vrach/495857-sabadash/" onClick={() => window.open('https://prodoctorov.ru/ekaterinburg/vrach/495857-sabadash/')}>Через "Продокторов" (нажмите на это поле)</ListGroup.Item>
              <ListGroup.Item variant="success">Лично посетить нашу клинику</ListGroup.Item>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
        </Container>
      </Row>
    </>
  );
}

import React, { Component } from "react";
import FAbout from "../Compoments/FAbout";

export default class About extends Component {
  render() {
    return (
      <>
        <FAbout />
      </>
    );
  }
}

import React, { Component } from 'react';
import './FContacts.css';
import YandexMapLoader from './YandexMapLoader';
import { Container, Tab, Tabs, ListGroup } from 'react-bootstrap';
import LocationIcon from '../Pages/Images/Other/location.svg'
import MobileIcon from '../Pages/Images/Other/mobile.svg'
import TimeIcon from '../Pages/Images/Other/time.svg'

export default class FContacts extends Component {
  render() {
    return (
      <>
        <div className="about-layout d-md-flex flex-md-column align-items-center">
            <Container className="container-about d-md-flex flex-md-column mr-auto align-items-center gap-3">
              <h2>Наше местоположение и контакты</h2>
              {['sm'].map((breakpoint) => (
                <ListGroup key={breakpoint} horizontal={breakpoint} className="group-contacts my-2 mr-auto">
                  <ListGroup.Item className='list-contacts d-md-flex align-items-center flex-md-column'>
                    <img src={LocationIcon}
                    alt='location icon'
                    width={32}
                    />
                    г.Екатеринбург, пр. Академика Сахарова д.51
                  </ListGroup.Item>
                  <ListGroup.Item className='list-contacts d-md-flex align-items-center flex-md-column'>
                    <img src={MobileIcon}
                      alt='mobile icon'
                      width={32}
                      />
                      <div className='d-md-flex flex-md-column align-items-center'>
                      <p>Телефон:</p>
                      <a href='tel:+79221019112'>+7(922)101-911-2</a>
                      <p>Почта:</p>
                      <a href='mailto:klinika.khirurgiya@bk.ru'>klinika.khirurgiya@bk.ru</a>
                      </div>
                  </ListGroup.Item>
                  <ListGroup.Item className='list-contacts d-md-flex align-items-center flex-md-column'>
                    <img src={TimeIcon}
                      alt='time icon'
                      width={32}
                      />
                      <div className='d-md-flex flex-md-column align-items-center'>
                      <p>Часы работы:</p>
                      <p>Вт-Пт с 14:00 до 21:00</p>
                      <p>Сб с 14:00 до 20:00</p>
                      <p>Вс-Пн выходные дни</p>
                      </div>
                  </ListGroup.Item>
                </ListGroup>
              ))}
              <Tabs
                defaultActiveKey="3DMap"
                id="uncontrolled-tab-example"
                className="mb-3"
                >
                <Tab eventKey="3DMap" title="3D Карта">
                  <YandexMapLoader />
                </Tab>
              </Tabs>
            </Container>
        </div>
        
      </>
    )
  }
}
